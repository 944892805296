@import '../theme-bootstrap';
/*
  Colorbox Core Style:
  The following CSS is consistent between example themes and should not be altered.
*/
// @setup new site - this is cleaned up css from the plugin
// alter as needed per brand
// default is from example #3 dark bg, light overlay
$color-transparent--black: rgba(0, 0, 0, 0.8);

#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
  @include breakpoint($medium-up) {
    background: $color-white;
    .colorbox--video & {
      background: none;
    }
  }
}

#cboxOverlay {
  background: $color-transparent--black;
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: $color-white;
  padding: 30px 20px 20px 20px;
  box-sizing: content-box;
  @include breakpoint($landscape-up) {
    padding: 40px 35px 30px 35px;
  }
  .qs-overlay-cbox & {
    padding: 0;
  }
  .colorbox--video & {
    padding: 35px 10px;
    background: none;
    color: $color-white;
    @include breakpoint($landscape-up) {
      padding: 40px 30px;
    }
  }
}

#cboxTitle {
  margin: 0;
  position: absolute;
  top: -20px;
  left: 0;
  color: $color-gray;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox,
#cboxContent {
  box-sizing: border-box;
}
/*
  User Style:
  Change the following styles to modify the appearance of Colorbox.
*/

#colorbox {
  background-color: $color-white;
  outline: 0;
}

#cboxError {
  padding: 50px;
  border: 1px solid $color-black;
}

#cboxCurrent {
  position: absolute;
  top: -20px;
  right: 0px;
  color: $color-gray;
}
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: $color-white;
}

#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -32px;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxPrevious:hover {
  background-position: bottom left;
}

#cboxNext {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -32px;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxNext:hover {
  background-position: bottom right;
}

#cboxClose {
  position: absolute;
  top: 0;
  right: 0;
  line-height: normal;
  font-size: 0; // hides 'close' text generated from plugin script
  &::before {
    background-color: $color-white;
    color: $color-black;
    content: '\00D7';
    display: block;
    font-size: 2rem;
    height: 3rem;
    line-height: 1.5;
    width: 3rem;
  }
  .colorbox--video & {
    position: fixed;
    background: $color-white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0;
    color: $color-white;
    outline: 0;
    top: 15px;
    right: 15px;
    @include breakpoint($landscape-up) {
      height: 40px;
      width: 40px;
    }
    @include breakpoint($landscape-up) {
      right: 20px;
      padding: 0;
    }
    &:before {
      font-size: 22px;
      line-height: 22px;
      content: ' ';
      display: block;
      background: url(#{$base-theme-path}/img/icons/src/close.svg) no-repeat;
      background-position: center center;
      background-size: 25px 25px;
      height: inherit;
      outline: 0;
      @include breakpoint($landscape-up) {
        background-size: 30px 30px;
      }
      @include breakpoint($landscape-up) {
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
}
